/* eslint-disable */
// TODO: eslint 오류가 있는데, 확인하고 다시 작성할것!

function parse(rawData) {
  try {
    return JSON.parse(rawData)
  } catch (_) {
    return rawData
  }
}

function isEmpty(storage) {
  return !storage || storage === {}
}

export function getValueFrom(storage, key) {
  if (isEmpty(storage)) {
    return
  }

  const rawData = storage.getItem(key)
  if (!rawData) {
    return
  }

  return parse(rawData)
}

export function setValueTo(storage, key, data) {
  if (isEmpty(storage)) {
    return
  }
  return storage.setItem(key, JSON.stringify(data))
}
