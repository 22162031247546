import * as React from 'react'
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome'
import { faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons'

import { useScroll } from '../../hooks/use-scroll-event'

import * as styles from './index.module.scss'

// const TopUpIndicator = () => {
function TopUpIndicator() {
  const { isTop } = useScroll()

  return (
    <div
      id={styles.top}
      style={{
        opacity: isTop ? '0' : '1',
        pointerEvents: isTop ? 'none' : 'all',
      }}
      onClick={() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }}
      onKeyDown={() => void 0}
      role='button'
      tabIndex={0}
    >
      <Fa icon={faAngleDoubleUp} />
    </div>
  )
}

export default TopUpIndicator
