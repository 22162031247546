// import {
//   setValueToSessionStorage,
//   getValueFromSessionStorage,
// } from './sessionStorage';
import {
  getValueFromLocalStorage,
  setValueToLocalStorage,
} from './localStorage'

// const SESSION_STORAGE_KEY = '__felog_session_storage_key__';
const LOCAL_STORAGE_KEY = '__felog_local_storage_key__'

export function getTheme(defaultValue) {
  return getValueFromLocalStorage(`${LOCAL_STORAGE_KEY}/theme`) || defaultValue
}

export function setTheme(val) {
  return setValueToLocalStorage(`${LOCAL_STORAGE_KEY}/theme`, val)
}
