import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import FooterCopyrighterLink from '../footer-copyrighter-link'
import ExternalLink from '../external-link'

import './index.module.scss'

export const footerQuery = graphql`
  query {
    site {
      siteMetadata {
        author
      }
    }
  }
`

function Footer() {
  const {
    site: {
      siteMetadata: { author },
    },
  } = useStaticQuery(footerQuery)

  return (
    <footer>
      <FooterCopyrighterLink>
        {`© ${new Date().getFullYear()} ${author}`}
      </FooterCopyrighterLink>
      <span>{` | Built with `}</span>
      <ExternalLink to='https://www.gatsbyjs.com/gatsby-4/' ariaLabel='Gatsby'>
        Gatsby-v4
      </ExternalLink>{' '}
      ( inspired by{' '}
      <ExternalLink
        to='https://www.gatsbyjs.com/showcase/junhobaik.github.io'
        ariaLabel='Borderless'
      >
        Borderless
      </ExternalLink>{' '}
      )
    </footer>
  )
}

export default Footer
