import * as React from 'react'
import { Link } from 'gatsby'
import ExternalLink from '../external-link'

import { social } from '../../../gatsby-meta-config'

function createCopyrighterLink(): string {
  return (
    (social.github && `https://github.com/${social.github}`) ||
    (social.linkedin && `https://www.linkedin.com/in/${social.linkedin}/`) ||
    (social.twitter && `https://twitter.com/${social.twitter}`) ||
    `/`
  )
}

function FooterCopyrighterLink({ children }: CopyrighterLinkProps) {
  const copyrighterLink = createCopyrighterLink()

  return copyrighterLink === '/' ? (
    <Link to={copyrighterLink}>{children}</Link>
  ) : (
    <ExternalLink to={copyrighterLink} ariaLabel='Copyrighter'>
      {children}
    </ExternalLink>
  )
}

type CopyrighterLinkProps = {
  children: React.ReactNode
}

export default FooterCopyrighterLink
