// import * as Sentry from '@sentry/browser'
import {
  config as FaConfig,
  dom as FaDom,
} from '@fortawesome/fontawesome-svg-core'
import 'normalize.css'
import * as React from 'react'
import { ErrorInfo } from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/footer'
import Header from '../components/header'
import TopUpIndicator from '../components/top-up-indicator'
import * as styles from './index.module.scss'

FaConfig.autoAddCss = false
interface IProps {
  title?: string
}

/* eslint-disable react/prop-types */
class Layout extends React.Component<IProps> {
  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // eslint-disable-next-line
    console.error('Uncaught error:', error, errorInfo)
    // Sentry.withScope(scope => {
    //   Object.keys(errorInfo).forEach(key => {
    //     scope.setExtra(key, errorInfo[key])
    //   })
    //   Sentry.captureException(error)
    // })
  }

  shouldComponentUpdate(): boolean {
    return true
  }

  render() {
    const { title, children } = this.props
    // const rootPath = `${__PATH_PREFIX__}/`;

    return (
      <>
        <Helmet>
          <link rel='icon' href='data:;base64,iVBORw0KGgo=' />
          <style>{FaDom.css()}</style>
        </Helmet>

        {/* <SvgSettings /> */}

        <div id={styles.layout}>
          <Header title={title} />

          <div id={styles.content}>
            <main>{children}</main>
            <Footer />
          </div>

          <TopUpIndicator />
        </div>
      </>
    )
  }
}

export default Layout
