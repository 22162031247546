import { graphql, useStaticQuery } from 'gatsby'
import moment from 'moment'
import * as React from 'react'
import Helmet from 'react-helmet'
import { IProps, IQueryData } from './types'

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        siteUrl
        title
        description
        author
        keywords
      }
    }
    profile: file(absolutePath: { regex: "/author-profile.jpg/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          width: 50
          height: 50
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

function SEO({
  title,
  description,
  lang = `ko`,
  // meta = [],
  keywords = [],
  thumbnail,
  pubData,
}: IProps) {
  const {
    site: { siteMetadata },
    profile,
  }: IQueryData = useStaticQuery(detailsQuery)

  const metaDescription = description || siteMetadata.description
  const metaKeywords = [...(siteMetadata.keywords ?? []), ...keywords]

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${siteMetadata.title}`}
      meta={
        [
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: thumbnail,
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            name: `twitter:creator`,
            content: siteMetadata.author,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
          {
            name: `twitter:image`,
            content: thumbnail,
          },
        ].concat(
          metaKeywords.length > 0
            ? {
                name: `keywords`,
                content: metaKeywords.join(`, `),
              }
            : []
        )
        /* .concat(meta) */
      }
    >
      {pubData ? (
        <script type='application/ld+json'>
          {`{
  "@context": "https://schema.org",
  "@type": "Article",
  "datePublished": "${moment(new Date(pubData.date)).toISOString()}",
  ${
    pubData.update
      ? `"dateModified": "${moment(new Date(pubData.update)).toISOString()}",`
      : ''
  }
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "${siteMetadata.siteUrl}"
  },
  "author": {
    "@type": "Person",
    "name": "${siteMetadata.author}"
    "url": "https://github.com/eugenejeonme",
  },
  "headline": "${title}",
  ${
    profile?.childImageSharp.gatsbyImageData.images.fallback?.src
      ? `"publisher": {
            "@type" : "organization",
            "name" : "${siteMetadata.author}",
            "logo": {
              "@type": "ImageObject",
              "url": "${siteMetadata.siteUrl}${profile.childImageSharp.gatsbyImageData.images.fallback.src}"
            }
          },
          "image": ["${siteMetadata.siteUrl}${profile.childImageSharp.gatsbyImageData.images.fallback.src}"]`
      : `"publisher": {
            "@type" : "organization",
            "name" : "${siteMetadata.author}"
          },
          "image": []`
  }
}`}
        </script>
      ) : null}
    </Helmet>
  )
}

export default SEO
