const BODY = 'body'

export const getLocation = () =>
  typeof location !== `undefined` ? location : null
export const getPathname = (): string => getLocation()?.pathname || '/'

export const getElement = (selector: string): HTMLDivElement | null =>
  typeof document !== `undefined` ? document.querySelector(selector) : null
export const getElements = (
  selector: string
): NodeListOf<HTMLDivElement> | null =>
  typeof document !== `undefined` ? document.querySelectorAll(selector) : null

export const hasClass = (element: Element, className: string): boolean =>
  element.classList.contains(className)
export const addClass = (element: Element, className: string): void =>
  element.classList.add(className)
export const removeClass = (element: Element, className: string): void =>
  element.classList.remove(className)
export const togleClass = (
  element: Element,
  className: string,
  condition: boolean | undefined
): boolean => element.classList.toggle(className, condition)

export const getBody = () => getElement(BODY)
export const addClassToBody = (className: string): void => {
  const body = getBody()
  body && addClass(body, className)
}
export const removeClassToBody = (className: string): void => {
  const body = getBody()
  body && removeClass(body, className)
}
export const hasClassOfBody = (className: string): boolean | null => {
  const body = getBody()
  return body && hasClass(body, className)
}

export const getBodyScrollTop = (): number | null =>
  typeof document !== `undefined`
    ? document.body.scrollTop || document.documentElement.scrollTop
    : null

export const getWindowHeight = (): number | null =>
  typeof window !== `undefined`
    ? 'innerHeight' in window
      ? window.innerHeight
      : document.documentElement.offsetHeight
    : null

export const getScrollArea = (): number | null =>
  typeof window !== `undefined`
    ? document.body.clientHeight -
      ('innerHeight' in window
        ? window.innerHeight
        : document.documentElement.offsetHeight)
    : null

export const getScrollPercent = (): number => {
  const bodyScrollTop = getBodyScrollTop() || 0
  const scrollArea = getScrollArea() || 0
  const percent = Math.round((bodyScrollTop / scrollArea) * 100)
  return percent < 0 ? 0 : percent > 100 ? 100 : percent
}

export const getRect = (className: string) =>
  getElement(className)?.getBoundingClientRect()
export const getPosY = (
  className: string,
  { absolute } = { absolute: false }
): number => {
  const posY = getRect(className)?.y || 0
  return absolute ? posY + (getBodyScrollTop() || 0) : posY
}
export const getRectWith = (el: HTMLElement) => el.getBoundingClientRect()
export const getPosYWith = (
  el: HTMLElement,
  { absolute } = { absolute: false }
): number => {
  const posY = getRectWith(el).y
  return absolute ? posY + (getBodyScrollTop() || 0) : posY
}
