import { useState, useEffect, useCallback } from 'react'
import { throttle } from 'lodash'

import * as Dom from '../utils/dom'

export const useScrollEvent = (
  // eslint-disable-next-line
  onScrollEvent: (this: Document, ev: DocumentEventMap['scroll']) => any
  //deps: ReadonlyArray<any> = [],
): void =>
  useEffect(() => {
    if (typeof document !== `undefined`) {
      document.addEventListener('scroll', onScrollEvent, { passive: true })
      return () => document.removeEventListener('scroll', onScrollEvent)
    }
  }, [])

export const useScroll = (): {
  scrollPercent: number
  isTop: boolean
  isScrollDown: boolean
} => {
  const [, setLastScrollOffset] = useState(0)
  const [isScrollDown, setScrollDown] = useState(false)

  const [scrollPercent, setScrollPercent] = useState(0)
  const [isTop, setTop] = useState(true)

  const onScrollEvent = () => {
    const scrollTop = Dom.getBodyScrollTop() || 0
    const scrollArea = Dom.getScrollArea()

    setLastScrollOffset((prev: number) => {
      if (scrollTop < 0 || (scrollArea && scrollTop > scrollArea)) {
        return prev
      }

      if (prev !== scrollTop) {
        setScrollDown(prev < scrollTop)
      }

      return scrollTop
    })

    const currentScrollPercentage = Dom.getScrollPercent()
    setScrollPercent(currentScrollPercentage)
    setTop(currentScrollPercentage < 10)
  }

  useScrollEvent(useCallback(throttle(onScrollEvent), []))

  return {
    scrollPercent,
    isTop,
    isScrollDown,
  }
}
