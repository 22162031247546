import {
  faChevronRight,
  faMoon,
  faSun,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import * as Dom from '../../utils/dom'
import { isMobile } from '../../utils/mobile-manager'
import * as Storage from '../../utils/storage'
import * as styles from './index.module.scss'

export const themes = {
  light: 'light',
  dark: 'dark',
}

export function getTheme(isDark: boolean): string {
  return isDark ? themes.dark : themes.light
}

export function setTheme(theme: string): void {
  Dom.addClassToBody(theme)

  switch (theme) {
    case themes.dark: {
      Dom.removeClassToBody(themes.light)
      break
    }
    case themes.light: {
      Dom.removeClassToBody(themes.dark)
      break
    }
  }
}

function ThemeSwitch() {
  const [isDarkMode, setDarkMode] = useState(false)

  function handleChangeTheme(isDarkMode: boolean): void {
    const theme = getTheme(isDarkMode)

    Storage.setTheme(isDarkMode)
    setDarkMode(isDarkMode)
    setTheme(theme)
  }

  useEffect(() => {
    const isDarkMode = Storage.getTheme(Dom.hasClassOfBody(themes.dark))
    handleChangeTheme(isDarkMode)
  }, [])

  const toggleTheme = useCallback(
    () => handleChangeTheme(!isDarkMode),
    [isDarkMode]
  )

  const onMouseEnter = useCallback(() => {
    const toggle = Dom.getElement(`.${styles.themeToggleDescription}`)
    toggle && (toggle.style.opacity = '0.5')
  }, [])

  const onMouseLeave = useCallback(() => {
    const toggle = Dom.getElement(`.${styles.themeToggleDescription}`)
    toggle && (toggle.style.opacity = '0')
  }, [])

  return (
    <div className={styles.themeToggle}>
      <div
        className={styles.themeToggleDescription}
        style={{ display: isMobile() ? 'none' : 'flex' }}
      >
        <Fa
          icon={isDarkMode ? faMoon : faSun}
          className={isDarkMode ? styles.moon : styles.sun}
        />
        <Fa icon={faChevronRight} className={styles.chevronRight} />
      </div>

      <Fa
        icon={isDarkMode ? faSun : faMoon}
        className={isDarkMode ? styles.sun : styles.moon}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={toggleTheme}
        role='button'
        data-testid='switch-button'
      />
    </div>
  )
}

export default ThemeSwitch
