import * as React from 'react'

interface Props {
  to: string
  ariaLabel: string
  classNames?: string
  children: React.ReactNode
}

function ExternalLink({ to, ariaLabel, classNames = '', children }: Props) {
  return (
    <a
      className={classNames}
      href={to}
      aria-label={ariaLabel}
      target='_blank'
      rel='noopener noreferrer'
    >
      {children}
    </a>
  )
}

export default ExternalLink
