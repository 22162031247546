import { graphql, Link, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { useScroll } from '../../hooks/use-scroll-event'
import { IThumbnail } from '../../types/frontmatter'
import { ISite } from '../../types/site'
import { isMobile } from '../../utils/mobile-manager'
import Nav from '../nav'
import * as styles from './index.module.scss'

const headerQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
      }
    }
    profile: file(absolutePath: { regex: "/author-profile.jpg/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          width: 50
          height: 50
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

interface IProps {
  title?: string
}

interface IQueryData {
  site: ISite
  profile: IThumbnail
}

function Header({ title }: IProps) {
  const queryData: IQueryData = useStaticQuery(headerQuery)
  const { site, profile } = queryData
  const {
    siteMetadata: { title: siteTitle, author },
  } = site

  const postfixTitle =
    typeof title === `string` && title.trim() !== '' ? ` | ${title}` : undefined

  const {
    childImageSharp: { gatsbyImageData },
  } = profile

  const { isScrollDown } = useScroll()

  return (
    <div
      id={styles.header}
      className={`${isScrollDown ? styles.hide : styles.show} ${
        isMobile() ? styles.mobile : ''
      }`}
    >
      <div className={styles.headerTitle}>
        <Link to='/'>
          <div className={`${styles.headerProfileImageWrap}`}>
            <GatsbyImage image={gatsbyImageData} alt={author} />
          </div>
        </Link>

        <Link to='/'>
          <h1 className={styles.headerTitleText}>
            {siteTitle}
            {postfixTitle && postfixTitle}
          </h1>
        </Link>
      </div>

      <Nav />
    </div>
  )
}

export default Header
