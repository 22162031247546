import * as React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome'
import {
  faListOl,
  faListUl,
  faTags,
  faSearch,
} from '@fortawesome/free-solid-svg-icons'

import ThemeSwitch from '../theme-switch'

import * as styles from './index.module.scss'

function Nav() {
  return (
    <nav id={styles.nav}>
      <ThemeSwitch />

      <div>
        <Link to='/series' title='Series'>
          <Fa icon={faListOl} />
        </Link>
      </div>

      <div>
        <Link to='/categories' title='Categories'>
          <Fa icon={faListUl} />
        </Link>
      </div>

      <div>
        <Link to='/tags' title='Tags'>
          <Fa icon={faTags} />
        </Link>
      </div>

      <div>
        <Link to='/search' title='Search'>
          <Fa icon={faSearch} />
        </Link>
      </div>
    </nav>
  )
}

export default Nav
